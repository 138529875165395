import React from 'react';
import { Logo } from '../components/logo';
import styled from '@emotion/styled';
import { breakpoints, containerCss } from '../utils/global-styles';

const NavStyled = styled.nav`
  ${containerCss};
  display: flex;
  align-items: center;
`;

const LogoStyled = styled(Logo)`
  margin-right: 20px;
  max-width: 400px;

  @media ${breakpoints.navigationMenu} {
    margin-right: auto;
  }
`;

export const NavigationSection = ({ className }: { className?: string }) => (
  <NavStyled className={className}>
    <LogoStyled></LogoStyled>
  </NavStyled>
);
