import Typography from 'typography';
import { textColors } from './color-palette';

const typography = new Typography({
  baseFontSize: '16px',
  baseLineHeight: 1.6,
  headerColor: `${textColors.secondary}`,
  bodyColor: `${textColors.secondaryLight}`,
  blockMarginBottom: 0,
  headerWeight: 800,
  headerFontFamily: [`Montserrat`, `-apple-system`, `Helvetica`, `sans-serif`],
  bodyFontFamily: [`Montserrat`, `-apple-system`, `Helvetica`, `sans-serif`],
  googleFonts: [
    {
      name: 'Montserrat',
      styles: ['400', '600'],
    },
  ],
  overrideStyles: () => ({
    html: { boxSizing: 'border-box' },
    '*, *:before, *:after': { boxSizing: 'inherit' },
    '@media (min-width: 500px)': {
      html: {
        'font-size': '110%',
      },
    },
    '@media (min-width: 650px)': {
      html: {
        'font-size': '125%',
      },
    },
  }),
});

export const { adjustFontSizeTo, scale, rhythm, options } = typography;

export default typography;
