export const colors = {
  primary: '#001B48',
  primaryLight: '#5887FF',
  secondary: '#50D8D7',
  secondaryLight: '#BDFFFD',
  special: '#f00146',
  specialNav: '#365FBA',
};

export const textColors = {
  primary: '#D4D4D4',
  primaryLight: '#5887FF',
  secondary: '#2e343b',
  secondaryLight: '#34515e',
  special: '#1c2a43',
};
