import React from 'react';

import LogoSvg from '../images/logo.svg';
import LogoTextSvg from '../images/logo-text.svg';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { navigate } from 'gatsby';

const imageCss = css`
  margin: 0;
  height: auto;
`;

const LogoImgStyled = styled(LogoSvg)`
  ${imageCss};
  width: 19%;
`;

const TextImgStyled = styled(LogoTextSvg)`
  ${imageCss};
  width: 79%;
  margin-left: 2%;
`;

const WrapperStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const navigateToHome = () => navigate('https://www.consultchemdesign.com/');

export const Logo = ({ className }: { className?: string }) => {
  return (
    <WrapperStyled className={className} onClick={navigateToHome}>
      <LogoImgStyled></LogoImgStyled>
      <TextImgStyled></TextImgStyled>
    </WrapperStyled>
  );
};
