import { css } from '@emotion/core';
import { colors, textColors } from './color-palette';
import { adjustFontSizeTo, rhythm } from './typography';

import styles from '@reach/tooltip/styles.css';
(() => styles)();

export const containerCss = css`
  padding: ${rhythm(1)} ${rhythm(1)} 0 ${rhythm(1)};
`;

export const tooltipCss = css`
  [data-reach-tooltip] {
    background-color: #f1f1f1;
    white-space: normal;
    margin-right: 20px;
    ${css({ ...adjustFontSizeTo(15) })};
    line-height: ${rhythm(0.7)};
    max-width: 400px;
  }
`;

export const scrollBarCss = css`
  html {
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
  }

  body {
    overflow: overly;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: ${textColors.primary};
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: ${colors.primaryLight};
  }

  .contact-form-input {
    padding: ${rhythm(0.5)} ${rhythm(1)};
    margin-top: ${rhythm(0.5)};
    color: ${textColors.secondaryLight};
    border: 1px solid ${textColors.secondaryLight};
    border-radius: 26px;
    outline-color: ${textColors.primaryLight};
  }

  .contact-form-textarea::-webkit-scrollbar {
    display: none;
  }
`;

export const breakpoints = {
  navigationMenu: '(min-width: 1065px)',
  heroSection: '(min-width: 1100px)',
} as const;
