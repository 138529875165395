import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import SEO from './seo';
import styled from '@emotion/styled';
import { css, Global } from '@emotion/core';
import { scrollBarCss, tooltipCss } from '../utils/global-styles';
import BackgroundImage from 'gatsby-background-image';
import { rhythm } from '../utils/typography';
import { NavigationSection } from '../sections/navigation-section';

const MainContainer = styled.main`
  max-width: 1600px;
  margin: 0 auto;
`;

const Layout = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      desktop: file(relativePath: { eq: "tlo.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <>
      <SEO title={data.site.siteMetadata.title} />
      <Global
        styles={css`
          ${scrollBarCss}
          ${tooltipCss}

          .transitioned {
            transition: transform 0.1s;
            will-change: transform;
          }

          .scrolled {
            background: white;
            transform: translateY(-${rhythm(1)});
          }

          .scrolled-reversed {
            transform: translateY(${rhythm(1)});
          }
        `}
      />
      <BackgroundImage
        Tag="div"
        fluid={data.desktop.childImageSharp.fluid}
        css={css`
          z-index: -1;
          position: fixed !important;
          width: 100vw;
          height: 100vh;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
        `}
      ></BackgroundImage>

      <NavigationSection></NavigationSection>
      <MainContainer className={className}>{children}</MainContainer>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
